<template>
  <div>
    <a-layout>
      <a-layout-sider style="background: #FFFFFF;overflow: hidden; height: 100vh;" width="200px">
        <div style="padding: 30px 20px;">
          <div v-for="(item, index) in getMenus" :key="index" @click="goToUrl(index)" class="second-menu-item "
            :class="{ on: getSelectKey.indexOf(item.key) > -1 }">
            <i class="iconfont ft14" :class="item.icon"></i>
            <span class="ft14 ml10">{{ item.name }}</span>
          </div>
        </div>
      </a-layout-sider>
      <a-layout-content style="padding: 30px 20px;height: 100vh; background: #F7F8FA;">
        <div>
          <router-view></router-view>
        </div>
      </a-layout-content>
    </a-layout>

  </div>
</template>

<script>
export default {
  data() {
    return {
      showForm: false,
      isMulti: false,
      menus: [
        // {
        //   name: "店铺资料",
        //   icon: "iconshop",
        //   key: "setting_index",
        //   link: "/setting",
        // },
        // {
        //   name: "企业通知",
        //   icon: "iconnotice1",
        //   key: "setting_webhook",
        //   link: "/setting/webhook",
        // },
        {
          name: "账号管理",
          icon: "iconadminister",
          key: "setting_account",
          link: "/account",
        },
        // {
        //   name: "短信设置",
        //   icon: "iconmsg",
        //   key: "setting_sms",
        //   link: "/setting/sms",
        // },
        // {
        //   name: "积分设置",
        //   icon: "iconintegral",
        //   key: "setting_integral",
        //   link: "/setting/integral",
        // },
        // {
        //   name: "余额设置",
        //   icon: "iconmoney_setting",
        //   key: "setting_balance",
        //   link: "/setting/balance",
        // },
        // {
        //   name: "买单设置",
        //   icon: "iconmaidan_setting",
        //   key: "setting_buy",
        //   link: "/setting/buy",
        // },
        // {
        // 	name: '打印机设置',
        // 	icon: 'iconappoint_tab08',
        // 	key: 'setting_printer',
        // 	link: '/setting/printer'
        // },
        {
          name: "门店管理",
          icon: "iconhome_nav05",
          key: "setting_mendian",
          link: "/mendian",
        },
        {
          name: "提现申请",
          icon: "iconmaidan_setting",
          key: "setting_bank",
          link: "/setting/bank",
        },
        {
          name: "加入我们",
          icon: "icon-jiameng",
          key: "setting_join",
          link: "/setting/join",
        },
        // {
        //   name: "会员权益",
        //   icon: "iconhome_nav05",
        //   key: "vip",
        //   link: "/setting/vip",
        // },
      ],
    };
  },
  computed: {
    getMenus() {
      let newMenus = new Array();
      for (var a in this.menus) {
        newMenus.push(this.menus[a]);
        if (a == 0) {
          if (this.isMulti) {
            newMenus.push({
              name: "门店管理",
              icon: "iconhome_nav05",
              key: "setting_mendian",
              link: "/mendian",
            });
          }
        }
      }
      return newMenus;
    },
  },
  created() {
    this.checkMendian();
  },
  methods: {
    checkMendian() {
      this.$http
        .api("admin/getShopMendianSetting")
        .then((res) => {
          this.isMulti = res.is_multi == 1 ? true : false;
        })
        .catch((res) => { });
    },
    goToUrl(index) {
      this.refreshPage(this.getMenus[index].link);
    },
  },
};
</script>

<style></style>
